import React, { useMemo, useState } from "react";
import { useAuth } from "./ContextAndHooks/AuthContext";
import HowToPlay from "./Pages/HowToPlay";
import { Link, useNavigate } from "react-router-dom";
import { GoQuestion } from "react-icons/go";
import useSWR from "swr";
import { fetchData } from "./api/ClientFunction";
import { postData } from "./api/ClientFunction";


const HeaderBottom = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [amount,setAmount]=useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleHowToPlayClick = () => {
    setIsModalOpen((prev) => !prev);
  };
  const { data } = useSWR(`/user/read-crypto`, fetchData, {
    refreshInterval: 5000,
  });

  console.log(data,"crypto")

  const fetchCrypto=async(id)=>{    
    let res = await postData(`/user/read-singe-crypto/${id}`, {})
    setAmount(res?.wallet)
    console.log(res,'wallet')
  }
  const crypto=useMemo(()=>{
    if(data){
      let cryotoid=localStorage.getItem("crypto_id") ?? data?.data[0]?.id
      localStorage.setItem('crypto_id',data?.data[0]?.id)
      fetchCrypto(cryotoid)
      return data?.data
    }
  },[data])



  return (
    <div className="header-bottom">
      <Link to="/">
        <div className="header-left">
          <img src="images/logo.svg" alt="Logo" className="logo" />
        </div>
      </Link>
      <div className="header-right d-flex align-items-center">
        <button
          style={{
            background: "#DC3545",
            color: "white",
            border: "transparent",
          }}
          onClick={handleHowToPlayClick}
          className="btn btn-warning m-font-0 rounded-pill py-1 px-2 f-14 d-flex align-items-center h-26"
        >
          <span className="material-symbols-outlined f-18 me-1">
            {/* <GoQuestion size={17} /> */}
            help
          </span>{" "}
          How to Play
        </button>
        {isModalOpen && <HowToPlay onClose={() => setIsModalOpen(false)} />}
        <div style={{ background: "#DC3545" }} className="wallet-balance h-26">
          <span style={{ color: "white" }} id="wallet_balance">
            <select name="" id="" onChange={(e)=>{
              localStorage.setItem('crypto_id',e.target.value)
              fetchCrypto(e.target.value)
            }}>
              {crypto?.map((it)=>{
                return <option value={it?.id}>{it?.crypto}</option>
              })}
            </select>
            {/* ₹{user?.money}M */}
            {/* You may render wallet balance dynamically here */}
          </span>
        </div>

        <div style={{ background: "#DC3545" }} className="wallet-balance h-26">
          <span style={{ color: "white" }} id="wallet_balance">
          
            {amount?.wallet_amount ?? 0}
            {/* You may render wallet balance dynamically here */}
          </span>
        </div>
        <div style={{ background: "#DC3545" }} className="wallet-balance h-26">
          <span style={{ color: "white" }} id="wallet_balance">
          {amount?.winning_amount ?? 0}
            {/* You may render wallet balance dynamically here */}
          </span>
        </div>
        {/* <div className="wallet-balance h-26">
          <span id="wallet_balance">
            ₹{user?.bonusMoney}W
            
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderBottom;
